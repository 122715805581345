<template>
  <el-card class="main-card">
    <template #header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">进销存</el-breadcrumb-item>
        <el-breadcrumb-item>委外下单</el-breadcrumb-item>
      </el-breadcrumb>
    </template>
    <el-form :model="querys" ref="querys" label-width="70px" class="search-form">
      <el-row>
        <el-form-item label="关键词" prop="keyword">
          <el-col>
            <el-input v-model="querys.keyword" prefix-icon="Search"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="" label-width="20px">
          <el-button type="primary" icon="search" @click="fetchs()">
            搜索
          </el-button>
        </el-form-item>
      </el-row>
    </el-form>

    <div class="toolbar">
      <el-button type="primary" icon="plus" @click="add()">新增</el-button>
      <el-button type="danger" icon="delete" @click="del()">删除</el-button>
    </div>

    <static-table class="table" :pager="pager" :columns="querys.columns" :currHandler="currHandler" :sortHandler="sortHandler" :pageHandler="pageHandler" :sizeHandler="sizeHandler">
      <template v-slot:toolbar="scope">
        <el-button @click="edit(scope.row)" link size="small">[编辑]</el-button>
      </template>
      <template v-slot:birthday="scope">
        <i class="el-icon-time" v-show="scope.row.birthday"></i>
        <span style="margin-left: 10px">{{
          (scope.row.birthday || "").split(" ")[0]
        }}</span>
      </template>
    </static-table>
  </el-card>
</template>
<script>
import { fetchOutOrders, delOutOrder } from "@/api/outOrder";
import StaticTable from "@/components/static-table.vue";
export default {
  name: "OutOrderIndex",
  components: { StaticTable },
  data() {
    return {
      querys: {
        keyword: "",
        columns: [
          { type: "index", label: "序号" },
          { type: "hidden", prop: "id", label: "id" },
          { prop: "billType", label: "单据类型", sortable: true, searchable: true, width: "100", },
          { prop: "billCode", label: "单据编号", sortable: true, searchable: true, width: "150", },
          { prop: "billDate", label: "单据时间", width: "150", sortable: true, },
          { prop: "channel", label: "渠道", sortable: true, searchable: true, width: "100", },
          { prop: "detailCode", label: "SKU款号", sortable: true, searchable: true, width: "140", },
          { prop: "detailName", label: "SKU款名", sortable: true, searchable: true, width: "200", },
          { prop: "outOrderNo", label: "外部单号", sortable: true, searchable: true, width: "110", },
          { prop: "brandName", label: "品牌名称", sortable: true, searchable: true, width: "110", },
          { prop: "brandModelCode", label: "品牌款号", sortable: true, searchable: true, width: "110", },
          { prop: "factoryName", label: "工厂名称", sortable: true, searchable: true, width: "200", },
          { prop: "factoryModelCode", label: "工厂款号", sortable: true, searchable: true, width: "110", },
          { prop: "factoryOrderNo", label: "工厂订单号", sortable: true, searchable: true, width: "110", },
          { prop: "orderDate", label: "订单时间", width: "150", sortable: true },
          { prop: "metalCode", label: "金材质", sortable: true, searchable: true, width: "100", },
          { prop: "metalWeight", label: "金重", width: "110", sortable: true },
          { prop: "metalPrice", label: "金总价", width: "110", sortable: true },
          { prop: "metalUnit", label: "金单价", width: "110", sortable: true },
          { prop: "mainStoneCarat", label: "主石大小", width: "100", },
          { prop: "mainStoneCertNo", label: "主石证书号", sortable: true, searchable: true, width: "110", },
          { prop: "mainStone4C", label: "主石参数", sortable: true, searchable: true, width: "130", },
          { prop: "mainStonePrice", label: "主石价格", width: "110", sortable: true, },
          { prop: "mainStoneDate", label: "送石时间", width: "110", sortable: true, },

          { prop: "deliverPrice", label: "订单预计金额", width: "110" },
          { prop: "deliverDate", label: "交付时间", width: "130", sortable: true, },

          { prop: "laborPrice", label: "总工费", width: "110", sortable: true },
          { prop: "serialCode", label: "自定义条码", width: "120", sortable: true, searchable: true, },
          { prop: "brandSerialCode", label: "品牌条码", width: "120", sortable: true, searchable: true, },

          { prop: "goodsCertNo", label: "货品证书号", width: "120", sortable: true, searchable: true, },
          { prop: "goodsCostPrice", label: "成本价格", width: "100", sortable: true, },
          { prop: "remark", label: "备注", width: "120", searchable: true, },

          { prop: "createDate", label: "创建时间", width: "150", sortable: true, },
          { type: "toolbar", label: "操作" },
        ],
      },
      pager: {
        sidx: "ID", //默认的排序字段
        sord: "descending",
      },
      //multipleSelection: [], //多选的列
      currRow: null,
    };
  },
  mounted() {
    this.fetchs();
  },
  methods: {
    //this.$refs.dialog.**
    edit(row) {
      this.$router.push({ path: "detail", query: { id: row.id } });
    },
    add() {
      this.$router.push("detail");
    },
    del() {
      let that = this;
      this.$confirm("此操作将删除本页选择数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delOutOrder(that.currRow.id).then((res) => {
            if (res.code === 200) {
              that.$message({
                type: "success",
                message: "删除成功",
              });
              that.fetchs();
            }
          });
        })
        .catch(() => {
          that.$message({
            type: "info",
            message: "删除失败",
          });
        });
    },
    fetchs() {
      //移除多余的参数
      //let { data, records, total, ...query } = { ...this.pager, ...params };
      let { data, records, total, ...search } = {
        ...this.pager,
        ...this.querys,
      };
      fetchOutOrders(search).then((res) => {
        this.pager = { ...res.data };
      });
    },
    currHandler(row) {
      this.currRow = row;
    },
    pageHandler(page) {
      this.pager.page = page;
      this.fetchs();
    },
    sizeHandler(rows) {
      this.pager.rows = rows;
      this.fetchs();
    },
    sortHandler(order) {
      this.pager.sidx = order.prop;
      this.pager.sord = order.order;
      this.fetchs();
    },
  },
};
</script>
<style scoped>
</style>